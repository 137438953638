<template>
	  <el-carousel :interval="4000" type="card" height="200px">
	    <el-carousel-item v-for="item in img1">
	      <h3 class="medium"><img class="img" :src="item.img"></h3>
	    </el-carousel-item>
	  </el-carousel>

</template>

<script>
	export default{
		props:['cnm'],
		data(){
			return{
				img1:[
					{img:require("../assets/img/1.jpg")},
					{img:require("../assets/img/2.jpg")},
					{img:require("../assets/img/3.jpg")},
					{img:require("../assets/img/4.jpg")},
					{img:require("../assets/img/5.jpg")},
					{img:require("../assets/img/6.jpg")},
					{img:require("../assets/img/7.jpg")},
					{img:require("../assets/img/8.jpg")},
					
				]
			}
		}
	}
</script>

<style scoped>
	.el-carousel__item h3 {
	    color: #475669;
	    font-size: 14px;
	    opacity: 0.75;
	    line-height: 200px;
	    margin: 0;
	  }
	  
	  .el-carousel__item:nth-child(2n) {
	    background-color: #99a9bf;
	  }
	  
	  .el-carousel__item:nth-child(2n+1) {
	    background-color: #d3dce6;
	  }
	  .img{
		  width: 100%;
		  height: 100%;
	  }
</style>
