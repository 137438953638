import { render, staticRenderFns } from "./BookListItem.vue?vue&type=template&id=f60883fc&scoped=true&"
import script from "./BookListItem.vue?vue&type=script&lang=js&"
export * from "./BookListItem.vue?vue&type=script&lang=js&"
import style0 from "./BookListItem.vue?vue&type=style&index=0&id=f60883fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f60883fc",
  null
  
)

export default component.exports