import { render, staticRenderFns } from "./sjxiangq.vue?vue&type=template&id=7915d402&scoped=true&"
import script from "./sjxiangq.vue?vue&type=script&lang=js&"
export * from "./sjxiangq.vue?vue&type=script&lang=js&"
import style0 from "./sjxiangq.vue?vue&type=style&index=0&id=7915d402&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7915d402",
  null
  
)

export default component.exports